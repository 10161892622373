import Vue from 'vue'
import App from './App.vue'
import './css/index.css';
import VueCarousel from 'vue-carousel';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons';
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faFacebookSquare)
library.add(faTwitter)
library.add(faYoutube)
library.add(faBars)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.use(VueCarousel);

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')

