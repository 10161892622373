<template>
  <div class="container mx-auto">
    <nav class="absolute backdrop-blur flex flex-wrap items-center justify-between px-2 py-3 mb-3 z-10 w-10/12" :class="showMenu == false ?'':'backdrop-filter  backdrop-blur'">
      <div class="container px-4 mx-auto flex flex-wrap items-center justify-between">
        <div class="w-full relative flex justify-between lg:w-auto  px-4 lg:static lg:block lg:justify-start">
          <button v-on:click="showMenu= !showMenu" class="ml-auto xs:mr-8 cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none" type="button">
            <font-awesome-icon :icon="['fas', 'bars']" :style="{ color: 'white' }" size="lg"/>
          </button>
        </div>
        <div class="lg:flex flex-grow items-center" id="navbar" :class="showMenu == false ?'hidden':''">
          <ul class="flex flex-col lg:flex-row list-none ml-auto mr-48">
              <li class="nav-item flex">
                <a class="pr-1 py-2 flex items-center leading-snug text-white hover:opacity-75 mx-1" href="https://www.facebook.com/La-Guara-Roja-100359379090357">
                  <i class="fab facebook-square"></i> 
                  <font-awesome-icon :icon="['fab', 'facebook-square']" />
                </a>
<!--                 <a class="p-2 flex items-center leading-snug text-white hover:opacity-75 mx-1" href="#">
                  <font-awesome-icon :icon="['fab', 'twitter']" />
                </a> -->
                <a class="p-2 pl-1 flex items-center leading-snug text-white hover:opacity-75 mx-1" href="https://www.youtube.com/channel/UC5hAIIvZQ9acHaS1g0meNhg">
                  <font-awesome-icon :icon="['fab', 'youtube']" />
                </a>
              </li>
              <li class="nav-item">
                <a class="px-3 py-2 flex items-center text-sm uppercase font-bold leading-snug text-white hover:opacity-75" href="#" v-on:click="show_section('home')">
                  Inicio
                </a>
              </li>
              <li class="nav-item">
                <a class="px-3 py-2 flex items-center text-sm uppercase font-bold leading-snug text-white hover:opacity-75" href="#" v-on:click="show_section('laguara')">
                  La Guara
                </a>
              </li>
              <li class="nav-item">
                <a class="px-3 py-2 flex items-center text-sm uppercase font-bold leading-snug text-white hover:opacity-75" href="#" v-on:click="show_section('suhogar')">
                  Su Hogar 
                </a>
              </li>
              <li class="nav-item">
                <a class="px-3 py-2 flex items-center text-sm uppercase font-bold leading-snug text-white hover:opacity-75" href="#" v-on:click="show_section('alimentacion')">
                  Alimentación
                </a>
              </li>
              <li class="nav-item">
                <a class="px-3 py-2 flex items-center text-sm uppercase font-bold leading-snug text-white hover:opacity-75" href="#" v-on:click="show_section('amenazas')">
                  Amenazas
                </a>
              </li>
          </ul>
        </div>
      </div>
    </nav>
    <transition name="fade-y" mode="out-in">
      <div v-show="section == 'home'">
        <div class="grid grid-cols-1">
          <div class="rounded-lg bg-guara-red m-2 overflow-hidden min-h-70vh">
              <div class="grid lg:grid-cols-3 min-h-55vh content-center">
                <div class="p-2 pl-10">
                  <h1 class="text-white text-7xl my-3">El hogar de la guara</h1>
                  <p class="text-white text-xl my-2">
                    El hábitat de la guara roja consiste en un bosque 
                    subtropical húmedo. Este tipo de bosque se encuentra 
                    en zonas de clima tropical con un periodo de lluvias 
                    fuertes y un periodo de sequía en que algunos o todos 
                    los árboles pierden el follaje (las hojas). Las guaras rojas 
                    viven en el follaje alto de los árboles.
                  </p>
                </div>
                <div class="slider md:colspan-1 lg:col-span-2 flex items-end">
                  <carousel :perPageCustom="[[300, 1],[480, 1], [768, 1]]" :paginationEnabled="false"  ref="carousel"> 
                    <slide>
                      <img src="../assets/guara1.png" class="ml-auto">
                    </slide>
                    <slide>
                      <img src="../assets/guara2.png" class="ml-auto">
                    </slide>
                    <slide>
                      <img src="../assets/guara3.png" class="ml-auto">
                    </slide>
                  </carousel>
                </div>
                
              </div>
          </div>
            <div class="rounded-lg bg-guara-red m-2 overflow-hidden">
            <div class="slider md:colspan-1 lg:col-span-2 flex items-end">
                  <carousel :perPageCustom="[[300, 1],[480, 1], [768, 1]]" :paginationEnabled="false"  ref="carousel_secondary"> 
                    <slide>
                      <img src="../assets/banner.jpg">
                    </slide>
                    <slide>
                      <img src="../assets/banner2.jpg">
                    </slide>
                    <slide>
                      <img src="../assets/banner3.jpg">
                    </slide>
                    <slide>
                      <img src="../assets/banner4.jpg">
                    </slide>
                  </carousel>
                </div>
            </div>

        </div>
        <div class="grid lg:grid-cols-3">
        <div class="bg-white shadow space-x-4 p-4 m-2 overflow-hidden rounded-lg min-h-25vh bg-guara-red cursor-pointer hover:shadow-2xl transition-all duration-700"  v-on:click="loadVideo('xavC1YEFlJ0',3)">
          <h2 class="text-white font-bold lg:text-5xl sm:text-2xl">MO!</h2>
          <img src="../assets/mo.png" alt="mo" class="float-right object-contain"></div>
          <div class="bg-white shadow space-x-4 p-4 m-2 overflow-hidden rounded-lg min-h-25vh bg-campana bg-cover cursor-pointer hover:shadow-2xl transition-all duration-700" v-on:click="loadVideo('fjz5bObgBjc',2)">
            <h2  class="text-white font-bold lg:text-5xl sm:text-2xl">Nuestra campaña</h2>
          </div>
          <div class="bg-white shadow space-x-4 p-4 m-2 overflow-hidden rounded-lg min-h-25vh bg-adopta bg-cover cursor-pointer hover:shadow-2xl transition-all duration-700" v-on:click="show_section('cultura-maya')">
            <h2  class="text-white font-bold lg:text-5xl sm:text-2xl">Cultura Maya </h2>
          </div>
        </div>
        <div class="grid grid-cols-1">
          <img src="../assets/logos.jpg" alt="Institutions Logos" class="mx-auto my-10 object-contain" >
        </div>
      </div>
    </transition>
    <transition name="fade-y" mode="out-in">
    <div class="grid grid-cols-1"  v-show="section == 'laguara'">
        <div class="rounded-lg  bg-gradient-to-b from-green-600 to-green-800  m-2 overflow-hidden min-h-90vh">
              <div class="grid lg:grid-cols-2">
                <div class="flex items-center flex-column">
                  <h1 class="text-white text-3xl lg:text-7xl my-3 pt-10 pl-5 text-center">Características de la Guara Roja</h1>
                </div>
                <div class="">
                  <p>
                    <img src="../assets/laguara.png" alt="guara" class="mx-auto pt-8">
                  </p>
                </div>
              </div>
              <div class="grid lg:grid-cols-4 min-h-55vh content-center align-items-center p-3">
                <div class="py-2 px-5 lg:col-span-2 ">
                    <div class="h-1 bg-guara-red rounded-full my-4"></div>
                  <h2 class="text-white text-2xl my-3">¿Cuáles son las características de la guara roja?</h2>
                  <p class="text-white text-md my-2 text-justify">
                      La guara roja es un ave que realmente llama la atención 
                      por sus colores brillantes, su tamaño y su inteligencia. Ha 
                      sido la fascinación de muchos desde hace siglos. Es el 
                      único animal que lleva los colores primarios, los colores 
                      esenciales para mezclar los demás colores.
                  </p>
                  <p class="text-white text-md my-2 text-justify">
                      Al nacer, sin embargo, los pichones son pelones, ciegos 
                      y feos. Tardan semanas para obtener su plumaje de color 
                      y aún más tiempo hasta que boten las plumas grises 
                      de la adolescencia que llevan en el pecho. Las guaras 
                      jóvenes tienen los ojos grises, casi negros. Al crecer, los 
                      ojos se ponen amarillos, y se vuelve muy difícil calcular la 
                      edad de la guara. Igual, sin prueba de sangre (de ADN), 
                      es imposible decir el sexo de la guara
                  </p>
                  <p class="text-white text-md my-2 text-justify">
                        La guara es un ave grande: Mide entre 81 y 96 cm 
                        de largo y pesa de 1,060 a 1,123 g. Macho y hembra 
                        adultos son generalmente rojos, con azul en la parte 
                        baja del dorso. La cola es roja con una punta azul. En las 
                        alas hay plumas amarillas, que pueden tener una punta 
                        verde. La parte superior del pico (mandíbula superior) es 
                        clara con una mancha negra a cada lado, en la base. La 
                        parte inferior del pico (mandíbula inferior) es negra. Entre 
                        la mandíbula inferior y el ojo hay un área de piel blanca 
                        sin plumas
                  </p>
                  <div class="h-1 bg-guara-red rounded-full my-4"></div>
                  <h2 class="text-white text-2xl my-3">¿Cuántos años puede vivir la guara roja?</h2>
                  <p class="text-white text-md my-2 text-justify">
                      La guara roja vive una edad promedia de unos 30 años. 
                      Sin embargo, en la naturaleza, una guara generalmente 
                      no sobrevive más de unos quince años, por todos 
                      los peligros que hay (cazadores, pérdida de hábitat, 
                      animales silvestres, trampas etc.).
                  </p>
                  <p class="text-white text-md my-2 text-justify">
                      Una guara en cautiverio (en un zoológico o parque de aves) 
                      que es bien alimentada y cuidada, puede vivir hasta cien años.
                  </p>
                
                  <div class="h-1 bg-guara-red rounded-full my-4"></div>
                  <h2 class="text-white text-2xl my-3">¿Se puede mantener la guara roja como mascota?</h2>
                  <p class="text-white text-md my-2 text-justify">
                      ¡La guara roja no es una mascota! Hay guaras que viven 
                      en cautiverio (en jaulas o aviarios), pero solamente en 
                      parques de aves, zoológicos o en aviarios de criadores 
                      que saben darles el cuidado especial que necesitan. Las 
                      guaras necesitan mucho ejercicio, ¡y por eso requieren 
                      jaulas del tamaño de una pequeña casa! Hacen 
                      muchísima bulla y destruyen todo en su alrededor. 
                  </p>
                  </div>
                <div class="py-2 px-5 lg:col-span-1">
                  <div class="h-1 bg-guara-red rounded-full my-4"></div>
                <h2 class="text-white text-2xl my-3">¿La guara roja es un loro?</h2>
                <p class="text-white text-md my-2 text-justify">
                    La guara roja es un loro o papagayo. Es el nombre 
                    común para aves como las guaras, los periquitos y las 
                    cotorras. El nombre científico de los loros es Psittacidae. 
                    Todos tienen como característica un pico con una forma 
                    curvada.
                </p>
                <p class="text-white text-md my-2 text-justify">
                    Poseen una gran capacidad craneal, siendo uno de 
                    los grupos de aves más inteligentes. Viven en zonas 
                    tropicales, son buenos voladores y diestros escaladores 
                    en ramas y árboles.
                </p>
                 <div class="h-1 bg-guara-red rounded-full my-4"></div>
                  <h2 class="text-white text-2xl my-3">¿Puede hablar la guara roja?</h2>
                  <p class="text-white text-md my-2 text-justify text-justify">
                        Aunque a veces pareciera lo contrario, los loros no 
                        hablan: repiten sonidos (imitan), pero realmente no 
                        tienen idea qué es lo que dicen. Aunque, si por ejemplo, 
                        se le enseña la palabra “galleta” cada vez que se le 
                        da una galleta, el loro obviamente asocia el sonido de 
                        la palabra con la galleta, igual a como se entrena a un 
                        perro.
                  </p>
                </div>
                <div class="py-2 px-5 lg:col-span-1">
                                    <div class="h-1 bg-guara-red rounded-full my-4"></div>
                  <h2 class="text-white text-2xl my-3">¿Cuál es el nombre científico de la guara roja?</h2>
                  <p class="text-white text-md my-2 text-justify">
                      Todos los animales tienen un nombre científico. Es como 
                      tener un nombre y apellido. El nombre científico es en 
                      latín y es universal, o sea, es lo mismo en todas las partes 
                      del mundo. La clasificación científica de la guara roja es
                  </p>
                  <p class="text-white text-md my-2 text-justify">
                      Reino: Animalia
                      Filo: Chordata
                      Clase: Aves
                      Orden: Psittaciformes 
                      Familia: Psittacidae 
                      Género: Ara
                      Especie: maca
                  </p>
                  <p class="text-white text-md my-2 text-justify">
                      Generalmente referimos a la guara roja con el nombre 
                      del género y de la especie: Ara macao. Se escribe en 
                      cursiva y el nombre del género lleva mayúscula (el 
                      nombre de la especie no)
                  </p>
                  <p class="text-white text-md my-2 text-justify">
                      A pesar de que en cada país hay distintos nombres 
                      populares para la guara roja, al usar el nombre Ara 
                      macao, todos saben a qué ave se refiere. Esto no es 
                      el caso con los nombres populares. Algunos de ellos 
                      usados en América Latina son: guacamaya, paraba siete 
                      colores (Bolivia), arará (Colombia), guacamaya bandera 
                      (Panamá, Perú), papagayo, guacamaya escarlata
                  </p>
                </div>
                                  
            </div>
        </div>
    </div>
    </transition>
    <transition name="fade-y" mode="out-in">
      <div class="grid grid-cols-1"  v-show="section == 'suhogar'">
          <div class="rounded-lg bg-gradient-to-b from-red-600 to-red-800  m-2 overflow-hidden min-h-90vh">
                <div class="grid lg:grid-cols-2">
                  <div class="">
                    <p>
                      <img src="../assets/habitat.png" alt="guara" class="mx-auto pt-8">
                    </p>
                  </div>
                  <div class="flex items-center flex-column">
                    <h1 class="text-white text-7xl my-3 pt-10 pl-5 text-center">El Hábitat de la Guara Roja</h1>
                  </div>

                </div>
                <div class="grid md:grid-cols-4 min-h-55vh content-center align-items-center p-3">
                  <div class="py-2 px-5 lg:col-span-2">
                      <div class="h-1 bg-guara-red rounded-full my-4"></div>
                    <h2 class="text-white text-2xl my-3">¿Cómo es el hábitat de la guara roja?</h2>
                    <p class="text-white text-md my-2 text-justify">
                        El hábitat de la guara roja consiste en un bosque 
                        subtropical húmedo. Este tipo de bosque se encuentra 
                        en zonas de clima tropical con un periodo de lluvias 
                        fuertes y un periodo de sequía en que algunos o todos 
                        los árboles pierden el follaje (las hojas). Las guaras rojas 
                        viven en el follaje alto de los árboles
                    </p>
                    <p class="text-white text-md my-2 text-justify">
                        Hacen sus nidos a alturas no menos de 30 metros, en los 
                        huecos de los árboles. Les gusta vivir cerca de los ríos y 
                        quebradas y generalmente viven en grupos grandes.
                    </p>
                    <p class="text-white text-md my-2 text-justify">
                      Comen frutas y semillas que encuentran en la selva. 
                      Para encontrar suficiente comida, necesitan un área 
                      grande donde vivir, donde no sean perturbados por los 
                      humanos.
                    </p>
                    <div class="h-1 bg-guara-red rounded-full my-4"></div>
                    <h2 class="text-white text-2xl my-3">¿Hay guaras rojas en Copán?</h2>
                    <p class="text-white text-md my-2 text-justify">
                          Antes, hace muchos años, habían muchas guaras en 
                        Copán. Los mayas, los habitantes del valle que nos 
                        dejaron los edificios que ahora conocemos como las 
                        Ruinas de Copán, ya adoraban las guaras y usaban 
                        sus plumas para decorar los penachos de los reyes y 
                        sacerdotes
                    </p>
                    <p class="text-white text-md my-2 text-justify">
                        Al crecer la población, los mayas cortaban más y más 
                        árboles para expandir la ciudad y cultivar más alimentos. 
                        Con la tala de los árboles desapareció el bosque, hábitat 
                        natural de la guara roja. Es probable que al final de la 
                        civilización maya en Copán ya no hubieran tantas guaras 
                        como antes. 
                    </p>
                    <p class="text-white text-md my-2 text-justify">
                        Hoy en día no hay suficiente bosque alrededor Copán 
                        Ruinas para que las guaras sobrevivan sin ayuda del 
                        hombre. Pero guaras sí hay: una bandada vive en el 
                        bosque protegido del Parque Arqueológico Copán. 
                    </p>
                    <p class="text-white text-md my-2 text-justify">
                        Otro grupo vive en el Parque de Aves Montaña 
                        Guacamaya. Con un poco de ayuda de nosotros, estas 
                        aves pronto volarán de nuevo, libremente sobre el valle 
                        de Copán.
                    </p>
                  
                  
                    </div>
                  <div class="py-2 px-5 col-span-1">
                    <div class="h-1 bg-guara-red rounded-full my-4"></div>
                    <h2 class="text-white text-2xl my-3">¿Qué amenaza el hábitat de la guara roja?</h2>
                    <p class="text-white text-md my-2 text-justify">
                        Una de las amenazas para la guara roja siempre ha sido 
                        su propia belleza: Por siglos la gente las ha atrapado 
                        para venderlas por las plumas o como mascotas. Pero 
                        la amenaza más grande es la pérdida de su hábitat. Los 
                        humanos seguimos destruyendo los bosques para la 
                        construcción de casas o carreteras, milpas, leña o pasto 
                        para ganado, causando que cada año hayan menos 
                        guaras en el mundo.
                    </p>
                    <p class="text-white text-md my-2 text-justify">
                        Hoy en día, muchas personas están trabajando para 
                        recuperar y proteger el hábitat de la guara roja. Los 
                        gobiernos de todos los países en donde viven las guaras, 
                        han aprobado leyes para proteger las aves. Sin embargo, 
                        todavía hay mucho trabajo e investigación que hacer, 
                        para que este pájaro sea salvado de extinción
                    </p>

                  
                  </div>
                        <div class="py-2 px-5 lg:col-span-1">
                  <div class="h-1 bg-guara-red rounded-full my-4"></div>
                    <h2 class="text-white text-2xl my-3">¿En cuál zona geográfica viven las guaras?</h2>
                    <p class="text-white text-md my-2 text-justify text-justify">
                        La guara roja se encuentra 
                        en áreas silvestres (bosques 
                        subtropicales) entre el sur de 
                        México y la Amazonia en Brasil.
                    </p>
                    <p class="text-white text-md my-2 text-justify text-justify">
                      En Honduras viven guaras 
                        en libertad en las regiones 
                        montañosas de los departamentos 
                        de Atlántida, Colón, Olancho y 
                        Gracias a Dios
                    </p>
                  </div>           
              </div>
          </div>
      </div>
    </transition>
    <transition name="fade-y" mode="out-in">
      <div class="grid grid-cols-1"  v-show="section == 'alimentacion'">
          <div class="rounded-lg bg-gradient-to-b from-yellow-600 to-yellow-800  m-2 overflow-hidden min-h-90vh">
                <div class="grid lg:grid-cols-2">

                  <div class="flex items-center flex-column">
                    <h1 class="text-white xs:text-3xl xl:text-7xl my-3 pt-10 pl-5 text-center">Alimentación de la Guara Roja</h1>
                  </div>
                  <div class="">
                    <p>
                      <img src="../assets/alimentacion.png" alt="guara" class="ml-auto pt-8">
                    </p>
                  </div>
                </div>
                <div class="grid md:grid-cols-4 min-h-55vh content-center align-items-center p-3">
                  <div class="py-2 px-5 lg:col-span-2">
                      <div class="h-1 bg-guara-red rounded-full my-4"></div>
                    <h2 class="text-white text-2xl my-3">¿Que comen las Guaras Silvestres?</h2>
                    <p class="text-white text-md my-2 text-justify">
                        En la naturaleza, la mayor parte de la alimentación de 
                        la guara roja consiste en semillas y frutos. Las guaras 
                        rojas no son muy delicadas: comen casi todas las frutas 
                        y semillas que encuentran en su camino, por ejemplo 
                        guayaba, nances, masico tierno y jocote. 
                    </p>
                    <p class="text-white text-md my-2 text-justify">
                      La guara roja no caza, pero si se encuentra con un 
                        insecto o un pequeño animal muerto, como un ratón, 
                        también se lo coma. La guara toma agua de pozos, ríos 
                        y quebradas. Una cosa curiosa quese ha observado en 
                        América del Sur, es que las guaras y otros tipos de loro 
                        comen la arcilla de barrancos. Algunos científicos dicen 
                        que es porque contiene sal y los minerales necesarios 
                        en su dieta. 
                    </p>
                    <p class="text-white text-md my-2 text-justify">
                      Otros dicen que la arcilla les ayuda a protegerse contra 
                      los tóxicos que hay en algunas semillas y frutos. Lo que 
                      sí se ha observado es que las guaras comen más arcilla 
                      en la temporada que tienen pichones. En Honduras no 
                      se ven grandes bandadas de distintas especies de loros 
                      en los barrancos, pero es posible que las guaras acá 
                      coman lodo o arcilla cuando toman agua en las orillas 
                      de las fuentes de agua. 
                    </p>
                    <div class="h-1 bg-guara-red rounded-full my-4"></div>
                    <h2 class="text-white text-2xl my-3">¿Que comen las Guaras en cautiverio?</h2>
                    <p class="text-white text-md my-2 text-justify">
                        Las guaras en los zoológicos y parques de aves, tienen 
                        una dieta muy balanceada. Igual a las guaras silvestres 
                        comen más que todo frutas y semillas. Unas de las frutas favoritas son: banano, papaya, sandía, mango, melón y naranjas.
                        Las semillas que comen son: pequeños 
                        cocos, semillas de girasol y de cacahuate, todas con 
                        cáscara. La guara roja también come verduras como el 
                        ejote, güisquil, remolacha y zanahoria. Sin embargo, 
                        verduras como el brócoli, coliflor y la col son tóxicos 
                        para las guaras. 
                    </p>
                    <p class="text-white text-md my-2 text-justify">
                          Además, la guara roja come concentrado y maíz. Lo 
                          que las guaras NO deben comer, ¡es masa! La masa las 
                          llena, pero contiene muy pocos nutrientes. Esto no sólo 
                          es cierto para las guaras, sino para casi todos los pájaros en cautiverio: deben comer distintas frutas y 
                          semillas durante todo el día, y siempre deben tener acceso 
                          al agua fresca. Muchos pájaros que sólo comen masa, 
                          tienen los colores muy pálidos. A cambiarla dieta ya las 
                          plumas recuperan su color y brillo. La dieta balanceada 
                          (la cual es muy cara) es otra razón por la cual la guara 
                          roja no es una buena mascota
                    </p>
                  
                    </div>
                  <div class="py-2 px-5 lg:col-span-1">
                    <div class="h-1 bg-guara-red rounded-full my-4"></div>
                    <h2 class="text-white text-2xl my-3">¿Cómo comen?</h2>
                    <p class="text-white text-md my-2 text-justify">
                          Las guaras rojas usan sus garras para sostener el 
                          alimento mientras están comiendo. Usan su gran 
                          pico para abrir las semillas de concha dura. También tienen un cuello muy flexible que les ayuda 
                          mucho a quitar las conchas. La fuerza del pico les 
                          da la ventaja de poder abrir semillas que muchos 
                          otros pájaros no pueden.
                    </p>
                    <p class="text-white text-md my-2 text-justify">
                      Usan la punta de la lengua para probar la comida 
                      y para sacar las semillas de la cáscara.Las guaras 
                      tienen dos estómagos. El primero es para digerir 
                      la comida “suave” como las frutas.
                    </p>
                    <p class="text-white text-md my-2 text-justify">
                     En el segundo estómago se encuentran pequeñas 
                      piedras que la guara a veces come. Estas sirven 
                      para“moler” las semillas duras que llegan a este 
                      estómago
                    </p>
                    <div class="h-1 bg-guara-red rounded-full my-4"></div>
                    <h2 class="text-white text-2xl my-3">¿Las guaras dispersan semillas?</h2>
                    <p class="text-white text-md my-2 text-justify">
                        Hay muchos pájaros que ayudan a transportar semillas de árboles y flores: comen la fruta entera y 
                        luego dejan la semilla donde comieron, o en otro 
                        sitio, dentro de sus heces
                    </p>
                    <p class="text-white text-md my-2 text-justify">
                      las guaras rojas, como todos los loros, generalmente no lo hacen, porque son depredadores de 
                      semillas: comen y destruyan la semilla completamente, en lugar de comer sólo la fruta y dejar la 
                      semilla
                    </p>
                  </div>
                        <div class="py-2 px-5 lg:col-span-1">
                  <div class="h-1 bg-guara-red rounded-full my-4"></div>
                    <h2 class="text-white text-2xl my-3">¿Hay suficientes alimentos para las guaras que viven libremente en Copán?</h2>
                    <p class="text-white text-md my-2 text-justify text-justify">
                        Las guaras rojas que ahora vuelan libremente 
                        sobre le valle de Copán (en el parque de aves y en 
                        el parque arqueológico), reciben cada día alimentación adicional. También comen frutas y semillas 
                        que encuentran en su camino, pero las guaras 
                        están acostumbradas a que les den de comer, así 
                        que también son un poco perezosas.
                    </p>
                    <p class="text-white text-md my-2 text-justify text-justify">
                          Lo ideal sería que cada guara buscara su propia 
                          comida. Hay bastantes árboles frutales en el Valle 
                          de Copán para que coman bien, pero siempre se 
                          les dará alimentación adicional, porque el hábitat 
                          actual no soporta una población grande de guaras. A veces los visitantes del parque arqueológico 
                          dan comida a las guaras
                    </p>
                    <p class="text-white text-md my-2 text-justify text-justify">
                          Esto no es correcto, porque mucho de lo que les 
                            dan no es comida adecuada para las guaras, como 
                            churros, confites, tamales y galletas. Aunque es 
                            muy divertido dar de comer a las guaras, es mejor 
                            no hacerlo: las guaras se pueden enfermar y además se acostumbran a que la gente les alimente y 
                            dejan de buscar su propia comida
                    </p>
                  </div>           
              </div>
          </div>
      </div>
    </transition>
    <transition name="fade-y" mode="out-in">
      <div class="grid grid-cols-1"  v-show="section == 'amenazas'">
          <div class="rounded-lg bg-gradient-to-b from-blue-800 to-blue-600  m-2 overflow-hidden min-h-90vh">
                <div class="grid grid-cols-1">
                      <img src="../assets/amenazas.png" alt="guara" class="mx-auto pt-8">
                         <h1 class="text-white xs:text-3xl xl:text-7xl my-3 pt-10 pl-5 text-center">Amenazas para la Guara Roja</h1>
                </div>
                <div class="grid md:grid-cols-4 min-h-55vh content-center align-items-center p-3">
                  <div class="py-2 px-5 lg:col-span-2">
                      <div class="h-1 bg-guara-red rounded-full my-4"></div>
                    <h2 class="text-white text-2xl my-3">¿Cuáles son las amenazas más grandes para la conservación de la guara roja? </h2>
                    <p class="text-white text-md my-2 text-justify">
                      El humano es la amenaza más grande para la guara roja, 
                      por varias razones. En primer lugar, porque hay personas 
                      que atrapan a las guaras o sacan los pichones de los 
                      nidos para venderlos, como mascota o por sus plumas
                    </p>
                    <p class="text-white text-md my-2 text-justify">
                        La guara roja, por su colorido plumaje, es un ave muy 
                        querida. Sin embargo, muchas guaras no sobreviven al 
                        robo. El tráfico de la guara roja es ilegal. Es un delito 
                        grave por el cual uno puede ir a la cárcel
                    </p>
                    <p class="text-white text-md my-2 text-justify">
                      La segunda amenaza causada por los humanos, es la
                      pérdida del hábitat. La guara roja necesita un territorio
                      grande de bosque tropical para poder sobrevivir. Si la
                      gente tala los árboles para cultivar pastos para ganado,
                      para obtener madera o para construir carreteras o casas,
                      la guara ya no tiene donde buscar comida, ni donde poner
                      sus huevos. 
                    </p>
                    <p class="text-white text-md my-2 text-justify">
                      Otras amenazas para la guara roja son: la contaminación
                      del ambiente, especialmente de las fuentes de agua y los
                      incendios que destruyen su hábitat.
                    </p>
                    <div class="h-1 bg-guara-red rounded-full my-4"></div>
                    <h2 class="text-white text-2xl my-3">¿Cuáles son los enemigos naturales de la guara roja?</h2>
                    <p class="text-white text-md my-2 text-justify">
                       El humano es sin duda el enemigo 
                        más grande de la guara roja. Sin embargo, la guara roja silvestre también 
                        tiene bastantes enemigos. En la selva, 
                        sus enemigos naturales son los monos, 
                        culebras, jaguares, tigrillos, tacuacines, 
                        águilas y otras aves de rapiña que las 
                        pueden comer.
                    </p>
                    <p class="text-white text-md my-2 text-justify">
                        Un enemigo poco esperado es la abeja africana introducida, que compite 
                        con la guara roja por los huecos de los 
                        árboles, que ambas quieren usar como 
                        nido. Para protegerse, las guaras duermen en la noche en grupos grandes, 
                        en los árboles más altos. 
                    </p>
                  
                    </div>
                  <div class="py-2 px-5 lg:col-span-1">
                    <div class="h-1 bg-guara-red rounded-full my-4"></div>
                    <h2 class="text-white text-2xl my-3">¿Cuáles amenazas específicas hay en Copán?</h2>
                    <p class="text-white text-md my-2 text-justify">
                          Sabemos que los antiguos mayas que vivían en el Valle 
                          de Copán disfrutaban las apariciones frecuentes de 
                          las guaras rojas, volando por el cielo. Dicen algunos 
                          ancianos en Copán, que antes se veían las guaras 
                          volando en el valle, pero muy pocas veces. Pero desde 
                          hace bastante tiempo, ya desapareció la guara en 
                          este valle, más que todo por la pérdida del hábitat. 
                          Especialmente en la temporada que se cultivaba tabaco 
                          en el valle (hasta hace unos veinte años), casi ya no había 
                          bosque. Recientemente, se ha reforestado bastante y 
                          la guara roja fue reintroducida en Copán, primero en 
                          el Parque Arqueológico y luego en el Parque de Aves 
                          Macaw Mountain. Al inicio las guaras eran pocas y no las 
                          dejaban volar libremente, pero ahora puedes disfrutar 
                          en ambos lugares de guaras volando. El objetivo del 
                          proyecto Guaras en Libertad es que las guaras luego 
                          volarán por todo el valle, pero hay bastantes amenazas. 
                          En primer lugar, no hay tanto bosque en el valle y por 
                          esto no hay tanta comida. Puede ser que la guara 
                          aterrice en tu comunidad en un árbol de mango o 
                          nances para devorar unas frutas. Nosotros que vivimos 
                          en el valle de Copán, debemos permitir esto para que la 
                          guara sobreviva y forme parte de nuestro entorno.
                    </p>
                    <p class="text-white text-md my-2 text-justify">
                        Otra amenaza es la gente que atrapa o mata a las 
                        guaras. Hay que acordarse que esto es ilegal y casi 
                        siempre termina en la muerte de la guara, porque son 
                        aves muy delicadas
                    </p>
                  </div>
                        <div class="py-2 px-5 lg:col-span-1">
                  <div class="h-1 bg-guara-red rounded-full my-4"></div>
                    <h2 class="text-white text-2xl my-3">¿Qué podemos hacer nosotros para protegerlas?</h2>
                    <p class="text-white text-md my-2 text-justify text-justify">
                      ¡Las niñas y niños pueden ser los protagonistas en la 
                      que atrapan a las guaras, por pura ignorancia. ¡Pero los 
                      niños y las niñas ya saben mejor!
                    </p>
                    <p class="text-white text-md my-2 text-justify text-justify">
                      Cuando veas una guara en tu comunidad, hay que 
                      dejarla en paz. Si todo el mundo deja tranquila a la 
                      guara, es probable que vuelva. Jamás hay que tirarle 
                      piedras o dispararle con escopetas u hondas, ni siquiera 
                      cuando está comiendo una fruta de un árbol en nuestros 
                      patios. ¡Una fruta menos es un precio muy bajo que 
                      pagar por el placer de ver un ave tan linda y especial! 
                    </p>
                    <p class="text-white text-md my-2 text-justify text-justify">
                        Los niños y niñas también pueden jugar un papel 
                        muy importante en la educación sobre la guara roja, 
                        compartiendo lo que aprenden en la escuela con sus 
                        familiares en casa.
                    </p>
                  </div>           
              </div>
          </div>
      </div>
    </transition>
    <transition name="fade-y" mode="out-in">
      <div class="grid grid-cols-1"  v-show="section == 'cultura-maya'">
          <div class="rounded-lg bg-gradient-to-b from-gray-800 to-red-600  m-2 overflow-hidden min-h-90vh">
                <div class="grid grid-cols-2 items-center">
                      <img src="../assets/ove.png" alt="guara" class="mx-auto pt-8">
                         <h1 class="text-white lg:text-7xl my-3 pt-10 pl-5 text-center">Guara roja en la Antigua Cultura Maya</h1>
                </div>
                <div class="grid md:grid-cols-4 min-h-55vh content-center align-items-center p-3">
                  <div class="py-2 px-5 lg:col-span-2">
                      <div class="h-1 bg-guara-red rounded-full my-4"></div>
                    <h2 class="text-white text-2xl my-3">¿Por qué era importante la guara roja para los mayas?</h2>
                    <p class="text-white text-md my-2 text-justify">
                          Cada uno de nosotros que ha tenido el placer de ver 
                          una guara roja volando sabe que es una vista majestuosa 
                          e impactante. Para nuestros antepasados no era nada 
                          diferente: los colores brillantes, el tamaño y los gritos 
                          de la guara roja lo hacen muy presente e impresionante, 
                          como un mensajero de los dioses en el cielo. Es por 
                          ser tan magnífica que hoy en día la guara roja es el ave 
                          nacional de Honduras, igual a como era sagrada para 
                          nuestros ancestros los mayas. 
                    </p>
                    <p class="text-white text-md my-2 text-justify">
                        Quizás por el color predominante de su plumaje (el rojo), 
                        se consideraba la guara roja un representante de K’inich 
                        Ahau, el poderoso dios del sol, con sus plumas tan 
                        brillantes como el sol recién salido.
                    </p>
                    <p class="text-white text-md my-2 text-justify">
                        Probablemente involucraban las guaras en las 
                        ceremonias religiosas. Las plumas de la guara roja 
                        se usaban para decorar los tocados, trajes, joyería y 
                        escudos de los reyes y otros miembros de la nobleza. 
                    </p>
                    <p class="text-white text-md my-2 text-justify">
                      Otras amenazas para la guara roja son: la contaminación
                      del ambiente, especialmente de las fuentes de agua y los
                      incendios que destruyen su hábitat.
                    </p>
                    <div class="h-1 bg-guara-red rounded-full my-4"></div>
                    <h2 class="text-white text-2xl my-3">¿Qué es el Popol Vuh?</h2>
                    <p class="text-white text-md my-2 text-justify">
                        El Popol Vuh o Popol Wuj es una recopilación de 
                        leyendas de los maya q’uiché, un pueblo maya de las 
                        tierras altas de Guatemala. El nombre del documento 
                        se traduce como “Libro del Consejo” o “Libro de 
                        la Comunidad” y es un libro sagrado de los maya 
                        q’uichés. La primera versión escrita del Popol Vuh en 
                        nuestro alfabeto, aparece en el Siglo 16, pero se piensa 
                        que sus leyendas son mucho más antiguas ya que se 
                        han encontrado imágenes que parecen referirse a las 
                        historias del Popol Vuh en varios sitios arqueológicos.
                    </p>
                    <p class="text-white text-md my-2 text-justify">
                        El Popol Vuh consiste en cuatro partes. La primera 
                        parte explica la creación del mundo y los primeros 
                        humanos, primero de barro y luego de madera, pero 
                        ambos intentos son infructuosos, así que los dioses 
                        los convierten en monos. La segunda parte cuenta las 
                        historias de los héroes gemelos Hunahpu e Ixbalanque 
                        en el inframundo. La tercera parte se trata de la creación 
                        de un nuevo hombre, esta vez hecho de maíz. La cuarta 
                        y última parte del Popol Vuh describe los origenes y 
                        parentesco del pueblo maya actual. 
                    </p>
                    </div>
                  <div class="py-2 px-5 lg:col-span-1">
                    <div class="h-1 bg-guara-red rounded-full my-4"></div>
                    <h2 class="text-white text-2xl my-3">¿Qué relación tiene la guara roja con los héroes gemelos?</h2>
                    <p class="text-white text-md my-2 text-justify">
                        Hunahpu e Ixbalanque son los héroes gemelos y 
                        semidioses cuyas aventuras narra el Popol Vuh. El padre 
                        de los gemelos, Hun Hunahpu, era un gran jugador 
                        de pelota y murió en un partido contra los Señores 
                        de Xibalbá, el inframundo. Los gemelos decidieron 
                        buscar su venganza. Los gemelos tienen poderes 
                        supernaturales, pero más que todo es su inteligencia y 
                        sus picardías las que finalmente los ayudan a vencer los 
                        Señores de Xibalbá. Después de esta victoria ellos se 
                        convierten en el Sol y la Luna. En una de sus aventuras, 
                        los gemelos se encuentran con Vucub-Caquix o Siete 
                        Guaras, un dios arrogante y orgulloso. En el cuento 
                        de este módulo se puede leer lo que pasa con el 
                        desafortunado Vucub-Caquix. Otra ocasión en que se 
                        menciona la guara roja es cuando los gemelos tienen 
                        que pasar la noche en un cuarto completamente oscuro.
                    </p>
                    <p class="text-white text-md my-2 text-justify">
                        Los Señores de Xibalbá les dieron dos antorchas y dos 
                        puros encendidos con la orden de devolverlos en el 
                        mismo estado la mañana siguiente. Los gemelos son lo 
                        suficiente inteligentes para no fumar los puros, ni dejar 
                        quemar las antorchas. En vez de esto, meten luciérnagas 
                        en los puros y plumas de la guara roja en las antorchas 
                        para que pareciera que estaban encendidas toda la 
                        noche y así volvieron a engañar a los Señores de Xibalbá
                    </p>
                  </div>
                        <div class="py-2 px-5 lg:col-span-1">
                  <div class="h-1 bg-guara-red rounded-full my-4"></div>
                    <h2 class="text-white text-2xl my-3">¿Los mayas tenían a las guaras como mascotas?</h2>
                    <p class="text-white text-md my-2 text-justify text-justify">
                        Es probable que los antiguos mayas mantuvieran las 
                        guaras como mascotas. Hay imágenes mayas en que 
                        aparecen en jaulas, sentados en palos sostenidos por 
                        personas y guaras amarradas por un laso. Mantenían 
                        otras aves también. En el arte maya se encuentran 
                        imágenes del quetzal, garzas, búhos, loros y pavos. 
                        Usaban algunas aves, como el pavo, para alimentarse 
                        y también como fuente de medicina para curar 
                        enfermedades. Los huevos del colibrí, por ejemplo, se 
                        usan entre algunos pueblos mayas hasta el día de hoy 
                        para curar hernias y la carne del zopilote para curarse 
                        de la malaria. Para atrapar las aves, los mayas usaban 
                        trampas, lazos y cerbatanas con las que aturdían a los 
                        animales.
                    </p>
                  </div>           
              </div>
          </div>
      </div>
    </transition>
     <div>
    
       <transition name="fade-y" mode="out-in">
        <div v-if="showModal" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"  v-on:click="showModal = !showModal">
          <div class="relative my-6 mx-auto max-w-10xl  w-full">
            <!--content-->
            <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              <!--body-->
              <div class="relative flex-auto">
                <div class="grid lg:grid-cols-3">
                  <div class="lg:col-span-2 p-3">
                    <iframe :width="'100%'" :height="videoHeight" :src="videoURL1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                  </div>
                  <div class="p2">
                    <div class="w-full h-half p-3" v-on:click="loadVideo('zKYXPY4neyU')">
                    <iframe :width="'100%'" :height="videoHeight /2"  :src="videoURL2" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                    <div class="w-full h-1/2 p-3" v-on:click="loadVideo('7pK7gXF6_2E')" v-if="videoURL3 != null">
                    <iframe :width="'100%'" :height="videoHeight /2"  :src="videoURL3" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </transition>
         <transition name="fade-y" mode="out-in">
       <div v-if="showModal" class="opacity-75 fixed inset-0 z-40 bg-black"   v-on:click="showModal = !showModal"></div>
      </transition>
  </div>

  </div>

</template>

<script>
export default {
  name: 'HomePage',
  data() {
    return {
      section:'home',
      showMenu:false,
      showModal:false,
      videoWidth:window.innerWidth * 0.80,
      videoHeight:(window.innerWidth > 768 ? window.innerHeight *0.80 :window.innerHeight/2),
      videoURL1:null,
      videoURL2:null,
      videoURL3:null
    }
  },
  mounted() {
  setTimeout( () => { this.$refs.carousel.computeCarouselWidth(); this.$refs.carousel_secondary.computeCarouselWidth();  window.dispatchEvent(new Event('resize'))}, 700)
  },
  methods: {
    show_section: function(section) {
      this.section = section
      this.showMenu = false; 
    },
    loadVideo: function(video, layout){
      var ids ={
        2:[
        "fjz5bObgBjc",
        "bt4JDBGXtiA",
        ],
        3:[
        "xavC1YEFlJ0",
        "rjeNFazom40",
        "u8uih0NMM-U",
        ]
      }
    this.videoURL1= "https://www.youtube.com/embed/" + video;
    var videos = ids[layout].filter((item) =>{
      return item != video
    })

    videos.forEach((element, index) => {
       this['videoURL' + (index +2)] = "https://www.youtube.com/embed/" + element 
    });

    if(layout == 2){
      this.videoURL3 =null; 
    }
      this.showModal = !this.showModal
    }
  }
}
</script>


